<template>
  <header class="header-component header-portal">
    <DxToolbar class="header-portal"> 
      <DxItem
        v-if="dadosPortal"
        location="before"
        css-class="header-title" 
      > 
        <div class="header-portal"> 
          <img style="height: 80px; margin-right: 10px" :src="dadosPortal.urlLogo"/>  
          {{ dadosPortal.nomePortal }}
        </div>
      </DxItem>  
    </DxToolbar>
  </header>
  <div>
    <h2 class="content-block">Verificar autenticidade diploma digital</h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <form @submit.prevent="buscarDadosDiploma">
          <DxForm ref="formBuscar" :form-data="formData" :disabled="formData.isLoading" :show-validation-summary="true">
            <DxGroupItem
              name="buscarDados"
              caption="Buscar Dados do Registro do Diploma" 
            > 
              <DxItem 
                data-field="codigo" 
                editor-type="dxTextBox"
                :editor-options="{ stylingMode: 'outlined', labelMode: 'floating',  mode: 'text' }"
              >  
                <DxLabel text="Código do diploma ou histórico escolar" :visible="false" />
                <DxStringLengthRule
                  :min="tamanhoMinimoCodigo" 
                  :message="`O código deve conter no mínimo ${tamanhoMinimoCodigo} caracteres`"
                />
                <DxRequiredRule message="Preencha o código do diploma ou histórico escolar." />  
              </DxItem> 
              <DxButtonItem
                :button-options="botaoBuscar"
                horizontal-alignment="right" 
              />
            </DxGroupItem> 
          </DxForm>  
        </form>
      </div> 
    </div>
    <div v-if="formDataResultado" class="content-block">
      <div class="dx-card responsive-paddings">  
        <div v-if="formDataResultado.situacaoDiploma === 'Ativo'">
          <DxForm :form-data="formDataResultado">   
            <DxGroupItem 
              caption="Dados do Aluno"
              :col-count="2"
            >
              <DxItem 
                data-field="nomePessoa" 
                editor-type="dxTextBox"
                :editor-options="{ readOnly: true, labelMode: 'floating', stylingMode: 'underlined', mode: 'text' }"
              > 
                <DxLabel text="Nome do Aluno" :visible="false" />
              </DxItem> 
              <DxItem 
                data-field="cpf" 
                editor-type="dxTextBox"
                :editor-options="{ readOnly: true, labelMode: 'floating', stylingMode: 'underlined', mode: 'text' }"
              > 
                <DxLabel text="CPF" :visible="false" />
              </DxItem>
            </DxGroupItem>
              <!--NOME DO CURSO-->
            <DxGroupItem
              caption="Dados do Curso"
              :col-count="3"  
            > 
              <DxItem 
                data-field="codigoEmecCurso" 
                editor-type="dxTextBox"
                :editor-options="{ readOnly: true, labelMode: 'floating', stylingMode: 'underlined', mode: 'text' }"
              > 
                <DxLabel text="Curso" :visible="false" />
              </DxItem>  
              <DxItem 
                data-field="dataIngresso" 
                editor-type="dxTextBox"
                :editor-options="{ readOnly: true, labelMode: 'floating', stylingMode: 'underlined', mode: 'text' }"
              > 
                <DxLabel text="Data de Ingresso" :visible="false" />
              </DxItem>
              <DxItem 
                data-field="dataConclusao" 
                editor-type="dxTextBox"
                :editor-options="{ readOnly: true, labelMode: 'floating', stylingMode: 'underlined', mode: 'text' }"
              > 
                <DxLabel text="Data de Conclusão" :visible="false" />
              </DxItem>  
            </DxGroupItem>

            <!--DADOS DO DIPLOMA-->  
            <DxGroupItem
              caption="Dados do Diploma"
              :col-count="3"  
            > 
              <DxItem 
                data-field="dataPublicacaoDiario" 
                editor-type="dxTextBox"
                :editor-options="{ readOnly: true, labelMode: 'floating', stylingMode: 'underlined', mode: 'text' }"
              > 
                <DxLabel text="Data Publicação do Diploma" :visible="false" />
              </DxItem> 
              <DxItem 
                data-field="situacaoDiploma" 
                editor-type="dxTextBox"
                :editor-options="{ readOnly: true, labelMode: 'floating', stylingMode: 'underlined', mode: 'text' }"
              > 
                <DxLabel text="Situação do Diploma" :visible="false" />
              </DxItem> 
            </DxGroupItem>
            
            <!--DADOS DO EXPEDIDORA-->  
            <DxGroupItem
              caption="Dados da Expedidora"
              :col-count="3"  
            >  
              <DxItem 
                data-field="codigoEmecExpedidora" 
                editor-type="dxTextBox"
                :editor-options="{ readOnly: true, labelMode: 'floating', stylingMode: 'underlined', mode: 'text' }"
              > 
                <DxLabel text="Expedidora do Diploma" :visible="false" />
              </DxItem> 
              <DxItem 
                data-field="dataExpedicaoDiploma" 
                editor-type="dxTextBox"
                :editor-options="{ readOnly: true, labelMode: 'floating', stylingMode: 'underlined', mode: 'text' }"
              > 
                <DxLabel text="Data de Expedição" :visible="false" />
              </DxItem>
              <DxItem 
                data-field="numExpedicao" 
                editor-type="dxTextBox"
                :editor-options="{ readOnly: true, labelMode: 'floating', stylingMode: 'underlined', mode: 'text' }"
              > 
                <DxLabel text="Número Expedição" :visible="false" />
              </DxItem>
            </DxGroupItem>

            <!--DADOS DO REGISTRADORA-->  
            <DxGroupItem
              caption="Dados da Registradora"
              :col-count="3"  
            >  
              <DxItem 
                data-field="codigoEmecRegistradora" 
                editor-type="dxTextBox"
                :editor-options="{ readOnly: true, labelMode: 'floating', stylingMode: 'underlined', mode: 'text' }"
              > 
                <DxLabel text="Registradora do Diploma" :visible="false" />
              </DxItem> 
              <DxItem 
                data-field="dataRegistro" 
                editor-type="dxTextBox"
                :editor-options="{ readOnly: true, labelMode: 'floating', stylingMode: 'underlined', mode: 'text' }"
              > 
                <DxLabel text="Data de Registro" :visible="false" />
              </DxItem>
              <DxItem 
                data-field="numRegistro" 
                editor-type="dxTextBox"
                :editor-options="{ readOnly: true, labelMode: 'floating', stylingMode: 'underlined', mode: 'text' }"
              > 
                <DxLabel text="Número Registro" :visible="false" />
              </DxItem>
            </DxGroupItem> 
            
          </DxForm>
          <div v-if="formDataResultado.permiteDownloadXML || formDataResultado.permiteDownloadRVDD" style="text-align: right">
            <DxDropDownButton 
              text="Baixar"
              icon="download"
              :items="botaoDownloads"
              display-expr="descricao"
              @item-click="executaAcaoBotaoDownload"
            />
          </div> 
        </div>
        <div v-else>
          <DxForm :form-data="formDataResultado"> 
            <!--DADOS DO DIPLOMA-->  
            <DxGroupItem
              caption="Dados do Diploma"
              :col-count="3"  
            >  
              <DxItem 
                data-field="situacaoDiploma" 
                editor-type="dxTextBox"
                :editor-options="{ readOnly: true, labelMode: 'floating', stylingMode: 'underlined', mode: 'text' }"
              > 
                <DxLabel text="Situação do Diploma" :visible="false" />
              </DxItem> 
            </DxGroupItem>
            <DxGroupItem 
              :col-count="1"  
            >    
              <DxItem 
                data-field="msgAnulacao" 
                editor-type="dxTextBox"
                :editor-options="{ readOnly: true, labelMode: 'floating', stylingMode: 'underlined', mode: 'text' }"
              > 
                <DxLabel text="Detalhes" :visible="false" />
              </DxItem>
            </DxGroupItem>
            <DxGroupItem 
              v-if="formDataResultado.possuiMotivoAnulacao"
              :col-count="1"  
            >    
              <DxItem 
                data-field="motivo" 
                editor-type="dxTextBox"
                :editor-options="{ readOnly: true, labelMode: 'floating', stylingMode: 'underlined', mode: 'text' }"
              > 
                <DxLabel text="Motivo" :visible="false" />
              </DxItem>
            </DxGroupItem>
          </DxForm>
        </div>  
      </div> 
    </div>
    <DxLoadPanel 
      v-model:visible="formData.isLoading"  
      shading-color="rgba(0,0,0,0.4)"
    /> 
  </div>
</template>

<script>  
import DxToolbar from "devextreme-vue/toolbar"; 
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import DxForm, {
  DxGroupItem,
  DxItem, 
  DxStringLengthRule,
  DxRequiredRule, 
  DxButtonItem,
  DxLabel
} from "devextreme-vue/form";

import DxDropDownButton from 'devextreme-vue/drop-down-button';

import { onMounted, reactive, ref } from 'vue';
import { api, validateApiError, downloadFile } from '@/services/api';
import endpoints from '@/endpoints';

export default {
  setup() {   
    const tamanhoMinimoCodigo = 12; 
    const formData = reactive({
      codigo: "",
      portalIsLoading: false,
      isLoading: false
    }); 

    const dadosPortal = reactive({
      isLoading: false,
      nomePortal: null,
      urlLogo: null
    })
    
    const formDataResultado = ref(null);

    const formBuscar = ref(null); 

    const botaoBuscar = {
      text: "Buscar",
      icon: "search", 
      type: "default", 
      useSubmitBehavior: true,
    };

    const botaoDownloads = ref([]);

    onMounted(() => { 
      buscarDadosPortal(); 
      const codigoDiploma = validarUrl();
      if (codigoDiploma && codigoDiploma.length >= tamanhoMinimoCodigo) {
        formData.codigo = codigoDiploma;
        buscarDadosDiploma();
      } 
    });  

    const validarUrl = () => {
      const codigoQuery = new URL(location.href).searchParams.get('codigo');
      if (codigoQuery) return codigoQuery;

      const hash = new URL(location.href).hash;
      if (hash.length >= tamanhoMinimoCodigo) return hash.replaceAll('#/', '');

      const pathName = new URL(location.href).pathname;
      if (pathName.length >= tamanhoMinimoCodigo) return pathName.replaceAll('/', '');

      return null;
    };
    
    const buscarDadosPortal = async () => { 
      formData.isLoading = true; 
      try {
        const response = await api.get(endpoints.BUSCAR_DADOS_PORTAL);
        dadosPortal.nomePortal = response.data.nomePortal;
        dadosPortal.urlLogo = response.data.urlLogo; 
        for (const element of document.getElementsByClassName('header-portal')) {
          element.style.backgroundColor = response.data.corHeader;
          element.style.color = response.data.corTextoHeader;
        } 
      } catch (err) {
        validateApiError(err);
      } finally { 
        formData.isLoading = false; 
      }
    } 

    const buscarDadosDiploma = async () => { 
      formData.isLoading = true;  
      const { codigo } = formData; 
      try {  
        const tipo = codigo.split('.').length === 2 ? "RVHE" : "RVDD";
        const response = await api.get(`${endpoints.BUSCAR_DIPLOMA}/${codigo}/${tipo}`);
        formDataResultado.value = response.data;
        botaoDownloads.value = [];
        if (formDataResultado.value.permiteDownloadXML) {
          botaoDownloads.value.push({ 
            descricao: 'XML',
            icon: 'textdocument',
            acao: () => baixarXmlPublico(),
          });
        }
        if (formDataResultado.value.permiteDownloadRVDD) { 
          botaoDownloads.value.push({
            descricao: 'RVDD',
            icon: 'pdffile',
            acao: () => baixarRVDD(),
          });
        }
      } catch (err) {
        validateApiError(err);
      } finally { 
        formData.isLoading = false; 
      }
    } 

    const executaAcaoBotaoDownload = (e) => { 
      e.itemData.acao();
    }

    const baixarXmlPublico = async () => {
      formData.isLoading = true; 
      const { codigo } = formData; 
      try {  
        const response = await api.get(`${endpoints.BAIXAR_XML_PUBLICO}/${codigo}`, { responseType: 'blob' });
        downloadFile(response.data, `xml_diploma_${Date.now()}.xml`, 'application/xml');
      } catch (err) {
        validateApiError(err);
      } finally { 
        formData.isLoading = false; 
      }
    }

    const baixarRVDD = async () => { 
      formData.isLoading = true; 
      const { codigo } = formData; 
      try {  
        const response = await api.get(`${endpoints.BAIXAR_RVDD}/${codigo}`, { responseType: 'blob' });
        downloadFile(response.data, `pdf_rvdd_${Date.now()}.pdf`, 'application/pdf');
      } catch (err) {
        validateApiError(err);
      } finally { 
        formData.isLoading = false; 
      }
    }

    return {
      dadosPortal,
      tamanhoMinimoCodigo, 
      formData, 
      formBuscar,
      formDataResultado, 
      botaoBuscar, 
      botaoDownloads,
      executaAcaoBotaoDownload,
      buscarDadosDiploma
    };
  },
  components: {
    DxToolbar,
    DxLoadPanel,
    DxForm, 
    DxStringLengthRule,
    DxRequiredRule,
    DxGroupItem,
    DxItem, 
    DxButtonItem, 
    DxLabel,
    DxDropDownButton
  }
};
</script>  

<style lang="scss">
@import "../themes/generated/variables.base.scss";
@import "../dx-styles.scss"; 

.header-portal {
  background-color: white
}

.header-component {
  flex: 0 0 auto;
  z-index: 1; 
  height: 100px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); 
  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }
} 

.dx-toolbar.header-toolbar .dx-toolbar-items-container { 
  height: 100px;
  background-color: $base-accent;
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px; 
  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  margin: -5px 1px 0px 20px; 
  padding: 8px;
  display: flex;
  align-items: center;
  font-family: 'open_sanssemibold','Open Sans',Arial,Helvetica,sans-serif;
  font-size: 2em;
  font-weight: 700;
  color: white;
  .screen-x-small & {
    font-size: 1em;
  }
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
} 
</style>