import { createRouter, createWebHistory } from "vue-router"; 

import Home from "./views/home-page"; 
import defaultLayout from "./layouts/side-nav-outer-toolbar";

const router = new createRouter({
  routes: [
    {
      path: "/:pathMatch(.*)*",
      name: "home",
      meta: {
        requiresAuth: false,
        layout: defaultLayout
      },
      component: Home
    },
  ],
  history: createWebHistory()
}); 

export default router;
