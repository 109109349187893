import axios from 'axios';  
import customNotify from '@/utils/custom-notify'; 

export const api = axios.create({ 
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

export const validateApiError = (err) => {
  const message = err.response.data ? err.response.data.Message : "Ocorreu um erro ao processar a requisição";  
  customNotify(message, "error");
}

export const downloadFile = (file, fileName, fileType) => {  
  const fileBlob = new Blob([file], { type: fileType });
  const downloadUrl = URL.createObjectURL(fileBlob); 
  const link = document.createElement("a");
  link.style = "display: none";
  link.href = downloadUrl;
  link.download = fileName; 
  link.click();
  link.remove();
  URL.revokeObjectURL(downloadUrl);
}