<template>
  <div class="side-nav-outer-toolbar"> 
    <dx-scroll-view ref="scrollViewRef" class="with-footer">
      <slot />
      <slot name="footer" />
    </dx-scroll-view>
  </div>
</template>

<script> 
import DxScrollView from "devextreme-vue/scroll-view"; 
 
import { computed, ref } from 'vue'; 

export default {
  props: { 
    isXSmall: Boolean,
    isLarge: Boolean
  },
  setup(props) {  
    const scrollViewRef = ref(null);  
    const drawerOptions = computed(() => {
      const shaderEnabled = !props.isLarge;

      return {
        menuMode: props.isLarge ? "shrink" : "overlap",
        menuRevealMode: props.isXSmall ? "slide" : "expand",
        minMenuSize: props.isXSmall ? 0 : 60,
        maxMenuSize: props.isXSmall ? 250 : undefined,
        closeOnOutsideClick: shaderEnabled,
        shaderEnabled
      };
    }); 

    return {   
      drawerOptions,
      scrollViewRef
    };
  },
  components: { 
    DxScrollView, 
  }
};
</script>

<style lang="scss">
.side-nav-outer-toolbar {
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
}

.layout-header {
  z-index: 1501;
}
</style>
